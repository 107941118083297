import { LayoutTypes } from "@/assets/ts/enums/detail-with-payment/LayoutTypes";
import { api } from "~/api/api";
import { ComplainInfo } from "./ComplainInfo";
import type { TApplicationState } from "~/components/global/application/types/TApplicationState";
import type { ApplicationState } from "~/components/global/application/models/ApplicationState";
import type { AsyncData } from "#app";
import type { TFetchError } from "@/assets/ts/types/TFetchError";
export abstract class ComplainInfoService {
	protected constructor(
		protected complainData: ComplainInfo,
		protected complainState: ApplicationState,
	) {}

	get getComplainData(): ComplainInfo {
		return this.complainData;
	}

	async checkReceiveComplaint() {
		if (this.getComplainData.getApplicationGuid) {
			const { data } = await this.fetchComplainInfo();

			this.complainState.state = <TApplicationState>data.value;
		}
	}

	get getComplainApiUrl(): string {
		const apiUrls: Record<LayoutTypes, string> = {
			[LayoutTypes.Penalty]: api.penaltyComplaintInfo(this.getComplainData.getApplicationGuid),
			[LayoutTypes.TollRoads]: api.roadBillComplaintInfo(this.getComplainData.getApplicationGuid),
		};

		return apiUrls[this.getComplainData.getApplicationType];
	}

	abstract fetchComplainInfo(): AsyncData<TApplicationState | null, TFetchError>;
}
